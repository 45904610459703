<template>
    <div class="goods_page flex">
      <div class="item_block" v-for="(item, index) in goodsList" :key="index" @click="jumpClick(item.id, item.name)">
        <div class="sec_item" >
          <div class="sec_item_img">
            <img :src="common.setImgLocation(item.images[0])" alt=""/>
          </div>
          <div class="sec_item_title ellipsis-1">
          {{item.name}}
          </div>
          <div class="sec_item_price" v-if="item.now_price"><span>￥</span>{{item.now_price}}</div>
          <div class="sec_item_price" v-else><span>￥</span>{{item.top_price}}</div>
          <div class="sec_item_remark">
            <div><i class="el-icon-box"></i>{{item.stock_num}}</div>
            <div><i class="el-icon-view"></i>{{item.views}}</div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "goodsList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    list: function () {
      this.goodsList = this.list;
    },
  },
  data() {
    return {
      goodsList: [],
    };
  },
  methods: {
    jumpClick(id, name) {
      // localStorage.removeItem('uname')
      // sessionStorage.setItem('uname', name);
      this.navigateTo(`/goodsDetail/goodsDetail/${id}`)
    }
  },
  mounted() {
    // console.log(this.goodsList, "---this.goodsList");
  },
};
</script>
<style lang="scss" scoped>
.goods_page {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
  width: 100%;
  .item_block {
    width: calc(100% / 5);
  }
  .sec_item {
    margin: 0 auto;
    width: 170px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 5px #bdbbbb;
    cursor: pointer;
    .sec_item_img {
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 5px;
      img {
        width: 100%;
      }
    }

    .sec_item_title {
      padding: 5px;
    }
    .sec_item_price {
      color: #ac3030;
      font-size: 18px;
      font-weight: 800;
      span {
        font-size: 14px;
        padding: 0 5px;
      }
    }
    .sec_item_remark {
      color: #99a9bf;
      font-size: 12px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      i {
        padding-right: 5px;
      }
    }
  }
}
</style>